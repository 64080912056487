.captcha-continer {
    > div {
       // display: flex;
       // align-items: center;
        // justify-content: start;
        
    }
    canvas {
        height: 37px
    }
    #reload_href {
        background-color: #F4912F;
        padding: 6px 13px;
       // margin-left: 1.4em;
        font-size: 0.8em;
        
    }
}