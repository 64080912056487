@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body{
    margin: 0; font-size: 13px;
}
.sidebar_text span {
    font-size: 15px ;
}
.para-margin {
    margin-bottom: 15px !important;
}
.MuiLinearProgress-bar1Determinate {
    background-color: #F4912F !important;
    height: 10px !important
}
::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}
::-webkit-scrollbar
{
    width: 8px;
    background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb
{
    background-color: grey;
}
.bg-blue {
    background-color: lightblue;

}
.bg-gray {
    background-color: #eee;
}
.theme-color-bg {
    background-color:#f6f8fc;
}
.login-page .MuiPaper-rounded {
    margin: 0;
}
.section_sep { margin: 0 auto; }
.event-right-box { border-bottom: solid 1px #ccc;}
.footer-bottom { background-color: #0B0B55; }
.footer-bottom .footer-bottom-text { float: right; }
.foot-menu ul{ list-style-position: inside;}
.title-margin { margin-bottom: 10px; }
.announcements-tabs-style {background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.announcements-tabs { background-color: #1761a0;}
/*.announcements-tab-data ul li{background-color: #ccc;}*/
.announcements-tab-data ul li:nth-child(even) {background-color: #eeeeee; color:#000}
.announcements-tab-data {padding:0 !important;}
 body .MuiDrawer-paper.MuiDrawer-paperAnchorRight {
    width: 35%;
}
main.main-panel {
    position: relative;
    float: right;
    width: calc(100% - 340px);
    transition: 0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1);
}

main.main-panel .MuiBox-root.css-sd49mo{
    min-height: calc(100vh - 174px); 
}
main.main-panel .MuiBox-root.css-uplawd{
    min-height: calc(100vh - 110px);
}
.sidebar-body main.main-panel {
    width: calc(100% - 80px);
}
.toggle-btn {
    box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12);
    border: solid 1px #999999;
    font-size: 24px;
    height: 41px;
    min-width: 41px;
    width: 41px;
    padding: 0;
    overflow: hidden;
    position: relative;
    line-height: 41px;
}
.screen-app-container {
    display: flex;
    /*min-height: 100vh;*/
    flex-direction: column;
    margin: 0;
}
.align-items-center {
    align-items: center !important;
}
.justify-content-center {
    justify-content: center !important;
}
.d-flex {
    display: flex !important;
}
.h-100 {
    height: 100vh;
}
.footer-bottom-text {
    text-align: right; display: block;
}
.footer-main {
    background-color: #0b0b55; padding-bottom: 16px;
}
.register-page {
    padding: 30px 0;
}
.force-overflow
{
	min-height: 450px;
}

/*
 *  STYLE 1
 */
 .announcements-tab-data {
    float: left;
    max-height: 465px;
    width: 100%;
    background: #ffffff;
    overflow-y: scroll;
   
 }
 .announcements-tab-data::-webkit-scrollbar-track
 {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
 }
 
.announcements-tab-data::-webkit-scrollbar
 {
	width: 10px;
	background-color: #F5F5F5;
 }
 
.announcements-tab-data::-webkit-scrollbar-thumb
 {
	background-color: #F90;	
	background-image: -webkit-linear-gradient(45deg,
    rgba(255, 255, 255, .2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .2) 50%,
    rgba(255, 255, 255, .2) 75%,
    transparent 75%,
    transparent)
 }
 .event-date {
    color:#4682B4 !important;
    font-weight: bold !important;
    font-size: 13px !important;
    margin-bottom: 10px !important;
 }
 .about-blue {
    color: #1761A0 !important;
    font-weight: bold !important;
    font-size: 13px !important;
 }
 .footer-logo {
    margin-bottom: 10px;
    display: inline-block;
 }
 .about-footer {
    margin-bottom: 15px;
 }
 .footer-menu {
    margin-top: 15px;
 }
 .footer-menu .foot-link{
    color: #000; text-decoration: none;
 }
 .foot-link-li {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: 6px;
    font-size: 14px;
 }
 .font-bold {font-weight: bold !important;}
 .foot-contact-info { margin-top: 8px; }
 .helpline-list { margin-bottom: 10px; }
.splide-ic { max-width: 70px; max-height: 70px; }
.custom-splide { text-align: center; }
.splide__pagination {bottom: -2rem;}
.splide__pagination__page.is-active {
    background: #C41E1B;
}
.splide__pagination__page {background: #585858; }
.splide__slide.custom-splide { background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    border: solid 2px #ad235e;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
 }
 .splideslide-align { text-align: center; }
 .date-blue{ color: #1761A0; font-size: 13px !important; text-align: center;  }
 .normal-title {color: #030303; text-align: center; font-size: 16px !important; font-weight: bold !important; margin: 5px 0 10px 0 !important; }
 .text-uppercase { text-transform: uppercase;}
 .slider-thumbnail { margin-top: 30px; }
 .slider-thumbnail-main { border-radius: 20px; }
 .splide__track--nav>.splide__list>.splide__slide { border: 0 !important; }
 .splide__track--nav>.splide__list>.splide__slide.is-active { border: 3px solid #ad235e !important; }
 .subheading-blue { color: #1761A0; font-size: 18px; padding-top: 15px; padding-bottom: 15px; font-weight: 500 !important; }
/*.splide-custom-text {display: inline-block; }*/


.card-stats {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    border: 0;
    margin-bottom: 30px;
    margin-top: 30px;
    border-radius: 6px;
    color: #333333;
    background: #fff;
    width: 100%;
    // border-radius: 16px !important;
    overflow: visible !important;
}
.card-stats-content {
    text-align: right; min-height: 100px;
}
.card-icon {
    border-radius: 3px;
    background-color: #999999;
    padding: 15px;
    margin-top: -40px;
    margin-right: 15px;
    float: left;
}
.card-ic { height: 1.5em !important; width: 1.5em !important; }
.card-stats-title { font-size: 18px;}
.card-stats-items { font-weight: bold !important; font-size: 22px !important; }
.card-stats-footer {
    text-align: left;
    border-top: solid 1px #eee;
    padding: 16px;
}
.card-warning {
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4);
    background: linear-gradient(60deg, #ffa726, #fb8c00);
}
.card-rose {
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4);
    background: linear-gradient(60deg, #ec407a, #d81b60);
}
.card-success {
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4);
    background: linear-gradient(60deg, #66bb6a, #43a047);
}
.card-info {
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 188, 212, 0.4);
    background: linear-gradient(60deg, #26c6da, #00acc1);
}
.button-right {text-align: right;}
.policy-label { transform: translate(14px, 0px) scale(0.75) !important;  }
.sidebar_icon { min-width: 40px !important;}
.footer-main {font-family: Roboto, "Helvetica Neue", Arial, sans-serif; }
.language_change_style { display: block; }
.bg-gray header.MuiAppBar-positionFixed{ background-color: transparent !important; }
.swal-modal {
    font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
}
.select-lag-inner #Language fieldset{
    border-color:#fff !important;
}
.select-lag-inner {
    color: #fff !important;
}
.select-lag-inner .MuiSelect-icon {
    color: #fff !important;
}
.select-lag-inner fieldset{
    border-color:#fff !important;
}
.round-icon {
    border-radius: 50px;
    height: 28px;
    width: 28px;
    margin: 4px !important;
    text-align: center;
    color: #fff;
    display: inline-block;
}
.sizechanger .sm-a.round-icon {
    background-color: #05ae6b !important;
}
.sizechanger .lg-a.round-icon {
    background-color: #1162e8 !important;
}
.sizechanger .ex-lg-a.round-icon {
    background-color: #cf0706 !important;
}
.paper-card {
    padding: 15px;
    //  border-radius: 16px !important;
}
.file-upload-style {
    text-align: center;
    border: 1px dashed #a5a5a5 !important;
    padding: 15px !important;
    box-sizing: border-box;
    width: 100%;
    background-color: #ededed;
    border-radius: 4px;
}
.icon-center {
    margin: 0 auto; font-size: 45px !important;
}
.or-text {
    text-align: center;
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 18px;
 }
 .Step-List {
    cursor: pointer;
 }
 .language_change_style #Language{
    min-width: 99px; padding: 8.5px 14px !important;
 }
 #language_change.css-wmmx20-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
    color: #fff !important;
 }
 .flg-ic {
    min-width: 38px !important;  margin-top: 3px;
 }
 .file-upload-style-main { margin-top: 0 !important; }
 .Proceeding-List {display: inline-block; background-color: #fff8fb; padding: 15px; margin: 10px 5px; border-radius: 8px; min-width: 200px;  }
 .get-otp { float: right; cursor: pointer; color: blue; }
 /*.register-page label { background-color: #fff; }*/
 .my-footer { background-size: cover;}
 .site-logo-style { background-color: #fff;}
 .main-nav a {padding: 10px 10px; display: block;}
.main-nav a.tab{color: #fff; text-decoration: none;}
.main-nav .active_tab {background-color: #ad235e; color: #ffffff; text-decoration: none; border-radius: 8px;}
.nav-mobile-style{
    background-color: rgba(0, 0, 0, 0.7); height: 100%;
}
.nav-mobile-style ul li a{
    color: #fff; text-decoration: none;
}
.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    background-color: transparent !important;
}
.footer-logo img{ width: 100%;}
.desktop-menu-sidebar .MuiListItemText-root span{
    font-size: 14px;
}
 /*resposnive */

/***************************large Screen********************************/
@media (min-width: 1920px) {

}

/***************************large Leptop********************************/
@media (max-width: 1440px) {
   
   
}

/***************************large med Leptop********************************/
@media screen and (min-device-width: 1280px) and (max-device-width: 1440px) { 

}

/***************************Leptop********************************/
@media screen and (max-width: 1280px) {

}

/***************************Tablet resolution********************************/
@media screen and (max-width: 1024px) {

}	


@media screen and (min-device-width: 1023px) and (max-device-width: 1024px) { 

}

@media screen and (max-width: 991px) {

}


@media screen and (max-width: 980px) {


}


@media screen and (max-width: 800px) {
   

}
   
   
   

@media screen and (max-width: 768px) {

}

/***************************Mobile resolution********************************/
@media screen and (max-width: 767px) {
    main.main-panel {
        width: 100%;
    }
   
}

@media screen and (max-width: 640px) {

}

/***************************Iphone resolution********************************/
@media screen and (max-width: 480px) {

}

@media screen and (max-width: 411px) {

}

@media screen and (max-width: 375px) {

}


@media screen and (max-width: 360px) {

}


@media screen and (max-width: 320px){

}